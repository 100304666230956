#hdr.hdr--integrate .hdrMiddle .gNav > ul > li > a{
    height:120px;
}
@media (max-width: 1199px){
.hdrMiddle .hdrInner {
    height: auto;
}
}
@media (max-width: 992px){
    .hdrMiddle .hdrInner {
        height: 60px;
    }
}
#hdrLogo img{
    height:84px;
    width: auto;
    @media (max-width: 992px){
        height: auto;
    }
}
#scrollBtn:before{
    bottom:-5px;
  }
@media (max-width: 992px){
    #hdr.hdr--integrate.active .hdrMiddle{
        padding: 10px 0;
    }
}
.gNav > ul > li > a strong{
    font-family: 'Playfair Display', serif;
    letter-spacing: 1px;
}
#scrollBtn{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}
.h2C{
    color:#5F4A8B;
}
.sec-about .h2C small{
    font-family: 'Montserrat', sans-serif;
    color:#D4CDE3;
    font-size: 12px;
}
.sec-category{
    padding: 100px 0;
    background-image: url('https://file003.shop-pro.jp/PA01245/852/img/sec3_bg.jpg');
    @media (max-width: 992px){
        background-image: url('https://file003.shop-pro.jp/PA01245/852/img/sec3_bg_sp.jpg');
    }
    background-position: center;
    background-size: cover;
    .tab--typeB .tabSwitch{
        margin-bottom: 25px;
        li{
            padding: 11px 18px;
            &.active{
                border-width: 1px;
            }
            &::after{
                inset: 10px 0 10px auto;
            }
            &:last-child::after{
                content: none;
            }
            @media (max-width: 992px){
                font-size: 16px!important;
            }
        }
    }
}
.conceptD2{
    .conceptD__img{
        img{
            height: 100%;
        }
    }
    .conceptD__text{
        background:#1b1b1b;
        margin-bottom: 0;
        .h2C{
            color:#fff;
            font-size: 20px;
            line-height: 48px;
            margin-bottom: 14px;
            letter-spacing: 1px;
            @media (max-width: 992px){
                font-size: 16px;
            }
        }
        p{
            font-size: 12px;
            line-height: 21px;
        }
        @media (max-width: 767px){
            padding: 40px 0;
            text-align: center;
            p{
                padding: 0 20px;
            }
        }
    }
}
.drawer__nav li a.drawerIcon .drawerIcon__text{
    line-height: 20px;
}
.copyright--typeA{
    background:#1B1B1B;
}
#toTop.toTop--primary a, .toDetailSearch.toTop--primary a{
    background:#F2F2F2;    
}
#toTop.toTop--primary a:after, .toDetailSearch.toTop--primary a:after{
    border-color:#1B1B1B;
}