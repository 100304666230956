$hdrBg: #1b1b1b; //PC時の画像の高さ
$hdrText: #fff; //PC時の画像の高さ

#hdr.hdr--integrate{
  .hdrMiddle{
    background: $hdrBg;
    transition: 0.3s;
  }
  .iconNav i,
  .iconNav > ul > li > a,
  .gNav > ul > li > a{
    color: $hdrText !important;
    &:after{
      border-color: $hdrText !important;
    }
    &:hover,&:hover i{
      color: $hdrText !important;
    }
  }
  .hdrMiddle__right{
    .searchForm{
      border-color: $hdrText !important;
      i{
        color: $hdrText !important;
      }
    }
    .searchForm__inner{
      background: transparent;
      input[type="text"]::-moz-placeholder{
        color: $hdrText !important;
      }
      input[type="text"]:-ms-input-placeholder{
        color: $hdrText !important;
      }
      input[type="text"],input[type="text"]::placeholder{
        color: $hdrText !important;
      }
    }
  }
  &.active{
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 9999;
    & + .h_box{
      display: none;
    }
    &:not(.integrate--fixed){
      .hdrMiddle{
        background: transparent;
        transition: 0.3s;
        box-shadow:none;
      }
      &.whiteColor--yet{
        .iconNav > ul > li > * > i,
        .iconNav > ul > li > a,
        .gNav > ul > li > a{
          color: #fff !important;
          &:after{
            border-color: #fff !important;
          }
          &:hover,&:hover i{
            color: #fff !important;
          }
        }
        .hdrMiddle__right{
          .searchForm{
            border-color: #fff !important;
            i{
              color: #fff !important;
            }
          }
          .searchForm__inner{
            background: transparent;
            input[type="text"]::-moz-placeholder{
              color: #fff !important;
            }
            input[type="text"]:-ms-input-placeholder{
              color: #fff !important;
            }
            input[type="text"],input[type="text"]::placeholder{
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
